/*Splitting();*/
import Parallax from 'parallax-js';

if(document.querySelector('[data-parallax-el]')) {
  const parallaxContainer = document.querySelectorAll('[data-parallax-el]');
  parallaxContainer.forEach((elem) => {
    let parallaxInstance = new Parallax(elem);
    parallaxInstance.enable();
  });
}

if(document.querySelector('[data-duplicate]')) {
  const originalArr = document.querySelectorAll('[data-original]');
  const duplicateArr = document.querySelectorAll('[data-duplicate]');

  originalArr.forEach(originalElement => {
    const originalValue = originalElement.dataset.original;
    duplicateArr.forEach(duplicateElement => {
      const duplicateValue = duplicateElement.dataset.duplicate;
      if (originalValue === duplicateValue) {
        duplicateElement.textContent = originalElement.textContent;
      }
    });
  });
}

if (document.querySelector('[data-splitting]')) {
  const splittingArr = document.querySelectorAll('[data-splitting]');
  splittingArr.forEach((item, i) => {
    const splitting = Splitting({
      target: item,
      by: 'lines',
    });
  });
}

window.initTilt = function initTilt() {
  if(document.querySelector('[data-tilt-init]') && window.innerWidth > 1024) {
    const tiltInitItemsArr = document.querySelectorAll('[data-tilt-init]');
    tiltInitItemsArr.forEach(item => {
      VanillaTilt.init(item, {
        max: 7,
        speed: 500,
      })
    })
  }
}
initTilt();
