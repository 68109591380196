const doubleRangeSlider = () => {
  const doubleRange = document.getElementsByClassName(
    'double-range-default-first'
  )[0];
  if (doubleRange) {
    const slider = doubleRange.querySelector('#double-range-default');
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || '£';
    const step = +slider.dataset.step;
    const inputsHidden = doubleRange.querySelectorAll(
      '.double-range-hidden-input'
    );
    const inputInfoMin = doubleRange.querySelector('.double-range-info-min');
    const inputInfoMax = doubleRange.querySelector('.double-range-info-max');
    const startValueMin = +inputsHidden[0].value;
    const startValueMax = +inputsHidden[1].value;

    window.rangeSlider = noUiSlider.create(slider, {
      start: [startValueMin, startValueMax],
      connect: true,
      padding: [0, 0],
      /*step: step,*/
      margin:10,
      range: {
        min: min,
        max: max,
      },
      step: Number(step),
      /*pips: {
        mode: "count",
        density: 6,
        values: 6,
        stepped: true,
        format: {
          to: function (value) {
            return value.toLocaleString("en-EN");
          },
        },
      },*/
    });

    slider.noUiSlider.on('update', function (values, handle) {
      inputInfoMin.textContent = `${
        unit + ' ' + Math.round(values[0]).toLocaleString('en-GB')
      }`;
      inputInfoMax.textContent = `${
        unit + ' ' + Math.round(values[1]).toLocaleString('en-GB')
      }`;
    });

    slider.noUiSlider.on('change', function (values, handle) {
      inputsHidden[handle].value = Math.round(values[handle]);
    });
  }
};
doubleRangeSlider();

const doubleRangeSliderSecond = () => {
  const doubleRange = document.getElementsByClassName(
      'double-range-default-second'
  )[0];
  if (doubleRange) {
    const slider = doubleRange.querySelector('#double-range-default-second');
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || '£';
    const step = +slider.dataset.step;
    const inputsHidden = doubleRange.querySelectorAll(
        '.double-range-hidden-input'
    );
    const inputInfoMin = doubleRange.querySelector('.double-range-info-min');
    const inputInfoMax = doubleRange.querySelector('.double-range-info-max');
    const startValueMin = +inputsHidden[0].value;
    const startValueMax = +inputsHidden[1].value;

    window.rangeSliderSecond = noUiSlider.create(slider, {
      start: [startValueMin, startValueMax],
      connect: true,
      padding: [0, 0],
      /*step: step,*/
      margin:10,
      range: {
        min: min,
        max: max,
      },
      step: Number(step),
      /*pips: {
        mode: "count",
        density: 6,
        values: 6,
        stepped: true,
        format: {
          to: function (value) {
            return value.toLocaleString("en-EN");
          },
        },
      },*/
    });

    slider.noUiSlider.on('update', function (values, handle) {
      inputInfoMin.textContent = `${
          unit + ' ' + Math.round(values[0]).toLocaleString('en-GB')
      }`;
      inputInfoMax.textContent = `${
          unit + ' ' + Math.round(values[1]).toLocaleString('en-GB')
      }`;
    });

    slider.noUiSlider.on('change', function (values, handle) {
      inputsHidden[handle].value = Math.round(values[handle]);
    });
  }
};
doubleRangeSliderSecond();

if(document.querySelector('.double-range-parent')) {
  const doubleRangeParentsArr = document.querySelectorAll('.double-range-parent');
  doubleRangeParentsArr.forEach(doubleRangeParent => {
    const inputCheckbox = doubleRangeParent.querySelector('input');

    inputCheckbox.addEventListener('change', () => {
      if(inputCheckbox.checked === true) {
        doubleRangeParent.classList.add('active');
      } else {
        doubleRangeParent.classList.remove('active');
      }
    })

    if(inputCheckbox.checked === true) {
      doubleRangeParent.classList.add('active');
    } else {
      doubleRangeParent.classList.remove('active');
    }
  })
}
