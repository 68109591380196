/*package.json add to  dependencies  */


/*js file */

window.choicesArray = [];

window.initChoices = function initChoices(elementsArr) {
    const choicesDOM = document.querySelector(elementsArr);
    if (choicesDOM) {
        const choicesArr = document.querySelectorAll(`${elementsArr}`);
        for (let i = 0; i < choicesArr.length; i++) {
            console.log('init');
            const parentContainer = choicesArr[i].parentNode;
            /*const list = parentContainer.querySelector(".default-select__list");*/

            const choices = new Choices(choicesArr[i], {
                searchEnabled: true,
                itemSelectText: '',
                placeholder: true,
                searchPlaceholderValue: 'Search',
            });
            choicesArray.push(choices);

            const choicesMultipleElement = parentContainer.querySelector(
                ".choices[data-type='select-multiple']"
            );

            if (choicesMultipleElement) {
                choicesMultipleElement.addEventListener('click', () => {
                    if (parentContainer.querySelector('.is-open')) {
                        choices.hideDropdown();
                    }
                });
            }

            /* New multiselect logic */
            if (
                choicesArr[i].multiple &&
                choicesArr[i].hasAttribute('data-multiple-list-logic')
            ) {
                let optionName = null;
                let optionValue = null;

                const multiplePlaceholder = parentContainer.querySelector(
                    '.choices__list--multiple'
                );

                const list = document.createElement('ul');
                list.className = 'meta-select__list';
                parentContainer.appendChild(list);

                function createListItem(optionName, optionValue) {
                    const listItem = document.createElement('li');
                    listItem.setAttribute('data-val', optionValue);
                    listItem.innerHTML = `${optionName}`;
                    listItem.classList.add('meta-select__list-item');
                    list.appendChild(listItem);

                    listItem.addEventListener('click', () => {
                        handleListItemClick(listItem);
                    });
                }

                function handleSelectedOptions() {
                    list.innerHTML = '';

                    const selectedOptions = Array.from(choicesArr[i].selectedOptions);

                    if (selectedOptions.length >= 1) {
                        list.classList.add('is-visible');
                    } else {
                        list.classList.remove('is-visible');
                    }

                    if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
                        choices.setChoiceByValue('');
                    }

                    selectedOptions.forEach(function (option) {
                        optionName = option.textContent;
                        optionValue = option.value;
                        if (optionName !== 'Select') {
                            createListItem(optionName, optionValue);
                        }
                    });

                    const listArr = list.querySelectorAll('.meta-select__list-item');
                    if (listArr.length === 1) {
                        multiplePlaceholder.textContent = optionName;
                    } else if (listArr.length >= 2) {
                        multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
                    } else {
                        multiplePlaceholder.textContent = 'Select';
                    }
                }

                function handleListItemClick(listItem) {
                    const optionValue = listItem.getAttribute('data-val');

                    choices.removeActiveItemsByValue(optionValue);
                    handleSelectedOptions();
                }

                handleSelectedOptions();

                choicesArr[i].addEventListener('change', function () {
                    handleSelectedOptions();
                });

                list.addEventListener('click', function (event) {
                    const liElement = event.target.closest('.meta-select__list-item');
                    if (liElement) {
                        handleListItemClick(liElement);
                    }
                });
            }
        }
    }
};

window.initChoices('[data-choices]');

window.resetChoice = function resetChoice(select) {
    let selectEl = typeof select === 'object' ? select : document.querySelector(select);
    let choicesArr = window.choicesArray
    if (choicesArr) {
        window.choicesArray.forEach(choices => {
            let choicesSelect = choices.passedElement.element;
            if (choicesSelect === selectEl) {
                let startValue = choices.config.choices[0].value;
                choices.setChoiceByValue(startValue);
            }
        })
    }
}

/*js file */
