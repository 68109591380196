import Swiper, {
  Autoplay,
  Navigation,
  EffectCreative,
  EffectFade,
} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let areasTabs = document.querySelectorAll('.areas-tabs-wrapper');

if (areasTabs) {
  areasTabs.forEach((tab) => {
    let areasSwitchers = tab.querySelectorAll('.areas-tab-switchers-item');
    let contentSwiper = tab.querySelector('.swiper-tab-content');
    let bgSwiper = tab.querySelector('.swiper-tab-bg');

    let swiperContent = new Swiper(contentSwiper, {
      modules: [Autoplay, Navigation, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 1,
      speed: 800,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
       crossFade: true,
      },
    });

    let swiperBgSwiper = new Swiper(bgSwiper, {
      modules: [Autoplay, Navigation, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 1,
      speed: 800,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    });

    areasSwitchers.forEach((button, idx) => {

      if(button.classList.contains('active') && location.search !== ''){
        swiperContent.slideTo(idx);
        swiperBgSwiper.slideTo(idx);
        scrollToEl('.areas-tab-swipers-wr|500')
      }
      button.addEventListener('click', () => {
        swiperContent.slideTo(idx);
        swiperBgSwiper.slideTo(idx);
        scrollToEl('.areas-tab-swipers-wr|500')
        areasSwitchers.forEach((el) => {
          el === button
            ? el.classList.add('active')
            : el.classList.remove('active');
        });
      });
    });
  });
}
