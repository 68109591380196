import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import SimpleBar from "simplebar";

if (document.getElementsByClassName('testimonials-section__slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll('.testimonials-section__slider');
  const testimonialsSliderButtonsArr = document.querySelectorAll('.testimonials-section__slider-buttons');
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 3) {
      loop = true;
      loopedSlides = slides.length * 3;
    }

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 8,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.prev'),
        nextEl: testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.next'),
      },
      breakpoints: {
        651: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 12,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
    });

    swiperObserver(testimonialsSwiper);

    /*setTimeout(function () {
      const testimonialsItemsArr = sliderEl.querySelectorAll('.testimonials-section__item-text');
      console.log(testimonialsItemsArr.length)
      for (let i = 0; i < testimonialsItemsArr.length; i++) {
        new SimpleBar(testimonialsItemsArr[i]);
      }
    }, 100)*/
  });
}
