const headerComponent = document.getElementsByClassName('header')[0];
// Header scroll
const scrollContainer = () => document.documentElement || document.body;

document.addEventListener('scroll', () => {
  if (scrollContainer().scrollTop > 50) {
    headerComponent.classList.add('scrolled');
  } else if (scrollContainer().scrollTop == 0) {
    headerComponent.classList.remove('scrolled');
  }
});

// menu handlers

// check mobile menu show/hide condition
const mobileMenuStartPoint = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
        '--mobile-menu-start-point'
    )
);
let isMobileMenuEnable =
    window.outerWidth <= mobileMenuStartPoint ||
    document.querySelector('.header-mobile');

/*const dropdownToggle = document.querySelectorAll('.menu-dropdown');

dropdownToggle.forEach((dropdown) => {
  const toggle = dropdown.querySelector('.menu-dropdown__arrow');
  toggle.addEventListener('click', function (e) {
    if (isMobileMenuEnable) {
      const menuItem = this.closest('.menu-item.dropdown');
      const dropdownMenu = this.nextElementSibling;

      // close all opened sub menu
      const activeDropdowns = document.querySelectorAll(
          '.menu-item.dropdown.active .dropdown-menu'
      );
      activeDropdowns.forEach((menu) => {
        menu.style.display = 'none';
        menu.closest('.dropdown').classList.remove('active');
      });

      // open current submenu
      menuItem.classList.toggle('active');
      if (menuItem.classList.contains('active')) {
        e.preventDefault();
        dropdownMenu.style.display = 'block';
      }
    }
  });
});*/

// toggle menu handler
function menuToggleFunc() {
  const menuToggle = document.querySelector('.menu-toggle');
  const navbarNav = document.querySelector('.navbar-nav');
  const headerCloseWrapper = document.querySelector('.header-close-wrapper');

  menuToggle.classList.toggle('active');
  navbarNav.classList.toggle('active');
  headerComponent.classList.toggle('open-menu');
  headerCloseWrapper.classList.toggle('active');

  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (scrollLock.getScrollState()) {
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');
  } else {
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
  }
}

// menu update function
window.updateMenu = function updateMenu() {
  isMobileMenuEnable =
      window.outerWidth <= mobileMenuStartPoint ||
      document.querySelector('.mobile-header');

  if (isMobileMenuEnable) {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach((menu) => {
      menu.style.display = '';
    });

    const headerCloseWrapper = document.querySelector('.header-close-wrapper');
    headerCloseWrapper.classList.remove('active');

    const activeMenuItems = document.querySelectorAll('.menu-item.active');
    activeMenuItems.forEach((item) => {
      item.classList.remove('active');
    });

    const navbarNav = document.querySelector('.navbar-nav');
    navbarNav.classList.remove('active');

    headerComponent.classList.remove('open-menu');

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle.classList.remove('active');

    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}

window.addEventListener('resize', updateMenu);
window.addEventListener('orientationchange', updateMenu);

// end of toggle menu handler

const menuToggle = document.querySelector('.menu-toggle');
const headerCloseWrapper = document.querySelector('.header-close-wrapper');
const menuHoverLinksArr = document.querySelectorAll('.hover-link');
if(headerComponent) {
  for (let i = 0; i < menuHoverLinksArr.length; i++) {
    menuHoverLinksArr[i].addEventListener('click', menuToggleFunc);
  }
}

menuToggle.addEventListener('click', menuToggleFunc);
headerCloseWrapper.addEventListener('click', menuToggleFunc);

// Hide and show Header when scroll up
function headerSticky() {
  let prevScrollPos = window.pageYOffset;

  window.addEventListener('scroll', () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = headerComponent.scrollHeight;

    if (window.scrollY > 0) {
      if (prevScrollPos > currentScrollPos) {
        headerComponent.style.top = 0;
        headerComponent.classList.add('scrolled');
      } else {
        headerComponent.style.top = `-${headerHeight + 3}px`;
      }
    }

    if (window.scrollY === 0) {
      headerComponent.classList.remove('scrolled');
      headerComponent.style.top = 0;
    }

    prevScrollPos = currentScrollPos;
  });

  if (window.scrollY !== 0) {
    headerComponent.classList.add('scrolled');
  }
}

headerSticky();

const languageDropdown = document.getElementsByClassName('top-header__language')[0];

if (languageDropdown) {
  languageDropdown.addEventListener('click', () => {
    if (window.innerWidth <= 1024) {
      languageDropdown.classList.toggle('active');
    }
  });

  document.addEventListener('click', (event) => {
    if (window.innerWidth <= 1024) {
      const targetElement = event.target;
      const isClickInsideLanguageDropdown = languageDropdown.contains(targetElement);

      if (!isClickInsideLanguageDropdown) {
        languageDropdown.classList.remove('active');
      }
    }
  });
}

if (document.getElementsByClassName('menu__list')[0]) {
  const menuList = document.querySelector('.menu__list');
  const menuToggleItemArr = menuList.querySelectorAll(
      '.menu__list .menu__item'
  );
  const flyingItem = menuList.querySelector('.flying-item');

  const leftOffset = menuToggleItemArr[0].offsetLeft;

  function activeToggle () {
    for (let i = 0; i < menuToggleItemArr.length; i++) {
      if(menuToggleItemArr[i].classList.contains('active')) {
        setTimeout(function () {
          const leftOffset = menuToggleItemArr[i].offsetLeft;
          flyingItem.style.width = `${menuToggleItemArr[i].clientWidth}px`;
          flyingItem.style.marginLeft = `${leftOffset}px`;
        }, 50)
      } else {
        flyingItem.style.opacity = 1;
      }
    }
  }

  menuToggleItemArr.forEach((toggleBtn, i) => {
    toggleBtn.addEventListener('mouseenter', () => {
      const leftOffset = toggleBtn.offsetLeft;

      flyingItem.style.opacity = 1;
      flyingItem.style.width = `${toggleBtn.clientWidth}px`;
      flyingItem.style.marginLeft = `${leftOffset}px`;
    });

    menuList.addEventListener('mouseleave', () => {
      activeToggle ();
    });
  });

  activeToggle ();
}

if (document.querySelector('.menu-dropdown')) {
  const menuDropdownsArr = document.querySelectorAll('.menu-dropdown');
  menuDropdownsArr.forEach((menuDropdown) => {
    const dropdownList = menuDropdown.querySelector('.menu-dropdown__list');

    menuDropdown.addEventListener('click', (e) => {
      if (window.innerWidth <= 1280) {
        if (!menuDropdown.classList.contains('is-active')) {
          e.preventDefault();
          menuDropdown.classList.add('is-active');
          dropdownList.classList.add('is-active');
        } else {
          menuDropdown.classList.remove('is-active');
          dropdownList.classList.remove('is-active');
        }
      }
    });
  });
}