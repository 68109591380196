import Swiper, {
    Autoplay,
    EffectCreative,
    EffectFade,
    Navigation,
    Controller,
} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let timelineSwiperWr = document.querySelectorAll('.timeline-swiper-wr');
timelineSwiperWr.forEach((el) => {
    if (el) {
        let cardsSwiperEl = el.querySelector('.swiper-timeline-date');
        let contentSwiperEl = el.querySelector('.swiper-timeline-content');
        let nextEl = el.querySelector('.slider-btn.next');
        let prevEl = el.querySelector('.slider-btn.prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let cardsSwiper = new Swiper(cardsSwiperEl, {
            modules: [Autoplay, Navigation, EffectCreative, Controller],
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 800,
            threshold: 10,
            grabCursor: true,
            limitProgress: 2,
            autoHeight: true,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {

                1100: {
                    creativeEffect: {
                        limitProgress: slidesCount,
                        prev: {
                            translate: ['-50%', 0, 100],
                            scale: 0,
                            opacity: 0,
                        },
                        next: {
                            translate: [20, 0, 0],
                        },
                    },
                },
            },

            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
        });

        let contentSwiper = new Swiper(contentSwiperEl, {
            modules: [EffectFade, Controller, EffectCreative],
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 800,
            threshold: 10,
            autoHeight: true,
           /* effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },*/
            effect: 'creative',
            creativeEffect: {
                limitProgress: slidesCount,
                prev: {
                    translate: ['-50%', 0, -400],
                    scale: 0,
                    opacity: 0,
                },
                next: {
                    translate: ['100%', 0, -400],
                    scale: 0,
                    opacity: 0
                },
            },
        });

        swiperObserver(cardsSwiper);
        cardsSwiper.update();

        cardsSwiper.controller.control = contentSwiper;
        contentSwiper.controller.control = cardsSwiper;
    }
});
