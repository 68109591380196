import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let newsSwiperWr = document.querySelectorAll('.news-swiper-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 15,
      slidesPerView: 'auto',
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1025: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
